export class SecureFileModel {
    constructor() { }
    Id: string;
    FileName: string;
    Status: SecureFileStatusModel;
    UpdatedBy: string;
    UpdatedDate: string;
    UserName: string;
    SharedEmails: string[];
    SharedFaces: string[];
    FaceShortUrl: string;
    EmailShortUrl: string;
    IsReadOnly: boolean;
}

export enum SecureFileStatusModel {
    Active = "Active",
    Expired = "Expired"
}


export enum SecureFileRegistrationStatusModel {
    Registered = "Registered",
    Expired = "Expired",
    Pending = "Pending"
}

export enum ValidityType {
    ONE_DAY,
    ONE_WEEK,
    ONE_MONTH
}

export enum DocumentPermissions {
    READONLY = 1,
    DOWNLOAD
}

export const mapToSecureFiles = (x: any): SecureFileModel => {
    return {
        Id: x.id,
        FileName: x.file_name,
        Status: x.status,
        UpdatedBy: x.updated_by,
        UpdatedDate: x.updated_date,
        UserName: x.user_name,
        SharedEmails: Array.isArray(x.sharedEmails) ? x.sharedEmails : [],
        SharedFaces: Array.isArray(x.sharedFaces) ? x.sharedFaces : [],
        FaceShortUrl: x.FaceShortUrl,
        EmailShortUrl: x.ShortUrl,
        IsReadOnly: x.isReadOnly
    } as SecureFileModel
}

export class FaceAuthenticationModel {
    constructor() { }
    Id: string;
    Token: string;
    Name: string;
    ContactPhoneNumber: string;
    IsUsed: boolean;
    IssuedTo: string;
    Status: number;
    IsRegistered: boolean;
    Organization: string;
    InviteSenderName: string;
    InviteLink: string;
    IssuedAt: string;
    ExpiresAt: string;
    CreatedAt: string;
    UpdatedAt: string;
}

export const mapToFaceAuthentication = (x: any): FaceAuthenticationModel => {
    return {
        Id: x.id,
        Token: x.token,
        Name: x.name,
        ContactPhoneNumber: x.issuedTo,
        IsUsed: x.is_used,
        IssuedTo: x.issuedTo,
        Status: x.status,
        IsRegistered: x.is_registered,
        Organization: x.organization,
        InviteSenderName: x.falkonUserName,
        InviteLink: x.inviteLink,
        IssuedAt: x.issuedAt,
        ExpiresAt: x.expiresAt,
        CreatedAt: x.createdAt,
        UpdatedAt: x.updatedAt,
        UserPhoneNumber: x?.phoneNumber

    } as FaceAuthenticationModel;
};

export class SecureFileInfoModel {
    constructor() { }
    Id: string;
    Access: string[];
    ExpiryDate: string;
    FileName: string;
    FileSize: number;
    FileStatus: string;
    UploadedDate: string;
    FaceAccess: string[];
    IsReadOnly: boolean;
}

export const mapToSecureFileInfoModel = (x) => {
    return {
        Id: x.id,
        Access: x.access || [],
        ExpiryDate: x.expiry_date,
        FileName: x.file_name,
        FileSize: x.file_size,
        FileStatus: x.file_status,
        UploadedDate: x.uploaded_date,
        FaceAccess: x.face_access || [],
        IsReadOnly: x.isReadOnly
    }
}

export class SecureFileAccessListModel {
    constructor() { }
    Id: string;
    AccessList: string[];
    FileName: string;
    UserID: string;
}


export const mapToSecureFileAccessListModel = (x) => {
    return {
        Id: x.id,
        AccessList: x.access_list === null ? [] : x.access_list,
        FileName: x.file_name,
        UserID: x.user_id,
    }
}

export class SecureFileLinksModel {
    constructor() { }
    Id: string;
    FileName: string;
    Url: string;
    Status: SecureFileStatusModel;
}

export const mapToSecureFileNameWithLinksModel = (x) => {
    return {
        Id: x.id,
        FileName: x.file_name,
        Url: x.url,
        Status: x.status
    }
}

export enum FaceRegistrationInviteLinkStatus {
    Registered = 1,
    Expired,
    Pending,
    NotRegistered,
    Delete,
}
