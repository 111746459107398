import axios, { AxiosHeaders, AxiosRequestConfig, ResponseType } from 'axios';
import Config from "../config";
import { AxiosInstance } from "../helpers/AxiosInstance";

const getAllSecureChatConversations = (PhoneNumber: string) => {
    return AxiosInstance.get(`${Config.API_SECOND_URL}/falkon/notify/api/v1/secureChat/participants/latest/message/${PhoneNumber}`)
}

const getSecureChatHistory = (partcipantId: string, page: number) => {
    return AxiosInstance.get(`${Config.API_SECOND_URL}/falkon/notify/api/v1/secureChat/history/messages/${partcipantId}?p=${page}`)
}

const genearateSecureChatLink = (customerPhoneNumber: string, data: {
    organization_id: string,
    falkon_user_number: string
}) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/falkon/notify/api/v1/secureChat/send/link/${customerPhoneNumber}`, data);
}

const getPartcipantData = (partcipantId: string) => {
    return AxiosInstance.get(`${Config.API_SECOND_URL}/falkon/notify/api/v1/secureChat/participant/id/${partcipantId}`)
}

const MarkAllMessagesAsRead = (PhoneNumber: string) => {
    return AxiosInstance.patch(`${Config.API_SECOND_URL}/falkon/notify/api/v1/secureChat/readAll/messages/${PhoneNumber}`)
}

const MarkAllSecureChatParticipantMessagesAsRead = (PhoneNumber: string,time:string) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/falkon/notify/api/v1/secureChat/read/receipt/${PhoneNumber}/${time}`)
}

export const SecureChatService = {

    genearateSecureChatLink,
    getPartcipantData,
    getSecureChatHistory,
    getAllSecureChatConversations,
    MarkAllMessagesAsRead,
    MarkAllSecureChatParticipantMessagesAsRead
}