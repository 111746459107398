import { DashboardType } from "./dashboard.model";

export class PlanMetrics {
    DashboardType: DashboardType;
    HasAccessToEmojis: boolean;
    HasFreeIncome: boolean;
    HasAccessToMMS: boolean;
    HasAccessToNotifications: boolean;
    GroupCreditsUsage: UsageModel;
    NormalTextingUsage: UsageModel;
    EndUsersUsage: UsageModel;
    ReceviedMessages: number;
    Delivered: number;
    HasOverQuota: boolean;
    InGracePeriod: boolean;
    HasAccessAutoReplies: boolean;
    HasMessageScheduling: boolean;
    HasAccessToFaceVault: boolean;
}

export class UsageModel {
    PlanCredits: number;
    TotalCredits: number;
    UsedCredits: number;
    AddOnCredits: number;
    GetPercentile: () => number;
    GetRemainingCredits: () => number;
}

export enum CreditType {
    PLAN_CREDITS_NORMAL = "pcn",
    PLAN_CREDITS_GROUP = "pcg",
    ADDON_CREDITS_NORMAL = "acn",
    ADDON_CREDITS_GROUP = "acg",
    pcn = "PLAN_CREDITS_NORMAL",
    pcg = "PLAN_CREDITS_GROUP",
    acn = "ADDON_CREDITS_NORMAL",
    acg = "ADDON_CREDITS_GROUP"
}



export const mapToUsageModel = (data: any): UsageModel => {
    return {
        PlanCredits: (data?.total) || 0,
        TotalCredits: (data?.total + data?.addOns) || 0,
        UsedCredits: data?.used || 0,
        AddOnCredits: (data?.addOns) || 0,
        GetRemainingCredits: function () {
            return this.TotalCredits - this.UsedCredits;
        },
        GetPercentile: function () {
            return Math.floor((this.UsedCredits / (this.TotalCredits || 1)) * 100);
        }
    } as UsageModel;
}

export const mapToPlanMetrics = (data: any): PlanMetrics => {
    return {
        DashboardType: data?.dashboardId as DashboardType,
        HasAccessToEmojis: !!data?.hasEmojis,
        HasFreeIncome: !!data?.hasFreeIncome,
        HasAccessToMMS: !!data?.hasMMSEnabled,
        HasAccessToNotifications: !!data?.hasNotificationsEnabled,
        ReceviedMessages: data?.received || 0,
        GroupCreditsUsage: mapToUsageModel(data?.groupMsgCredits),
        NormalTextingUsage: mapToUsageModel(data?.normalMsgCredits),
        EndUsersUsage: mapToUsageModel(data?.users),
        HasOverQuota: !!data?.hasOverQuota,
        InGracePeriod: !!data?.inGracePeriod,
        Delivered: data?.deliverd,
        HasAccessAutoReplies: !!data?.hasAutoReply,
        HasMessageScheduling: !!data?.hasMessageScheduling,
        HasAccessToFaceVault: true,   // x.hasFaceVault
    } as PlanMetrics;
}