import axios from 'axios';
import { SetUserRoleId, UpdatePlanMetrics } from '../actions';
import { AppRoutes } from '../components/routes/route.path';
import { ToasterTexts } from '../components/shared/constants';
import { ToasterType } from '../components/shared/toaster/toaster.model';
import Config from '../config';
import setToast from '../helpers/toaster.helper';
import store from '../stores/app-store';
import { SettingsService } from './SettingsService';
import { UserService } from './UserService';

var getTokenPromise: Promise<any> | null = null;


const login = (userName: string, password: string, stayLoggedIn: boolean, navigate) => {
    const dispatch = store.dispatch;
    if (!getTokenPromise) {
        var data = new URLSearchParams();
        data.append("grant_type", "password");
        data.append("username", userName);
        data.append("password", password);
        data.append("scope", stayLoggedIn ? "API offline_access openid" : "API offline_access openid");
        data.append("client_id", Config.CLIENT_ID);
        data.append("Client_secret", Config.CLIENT_SECRET);

        var conf = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        getTokenPromise = axios.post(`${Config.AUTH_URL}/connect/token`, data, conf).then(res => {
            SettingsService.hasTwoFactAuthEnabled().then((res) => {
                if (res) {
                    navigate(AppRoutes.TWO_FACTOR_AUTHENTICATION, { replace: true });
                    // navigate(0);
                    localStorage.setItem("2FAVerified", "false");
                }
                else {
                    localStorage.setItem("2FAVerified", "true");
                    UserService.getCurrentUser().then((res: any) => {
                        SetUserRoleId(dispatch, Number(res.data.roleId));
                        UpdatePlanMetrics(dispatch, res.data?.planTracker)
                        if (!!res.data && !res.data.lastLoggedIn) {
                            navigate(AppRoutes.RESET_PASSWORD_NEW, { replace: true });
                            // navigate(0);
                        }
                        else {
                            navigate(UserService.hasSysAdminRole() ? AppRoutes.PHONE_NUMBERS : AppRoutes.DASHBOARD, { replace: true });
                            window.location.pathname.includes("teams") && navigate(0);
                        }
                    })
                }
            })
                .catch((err) => {
                    console.log("Error", err);
                    setToast(ToasterTexts.SOMETHING_WENT_WRONG_MESSAGE, ToasterType.DANGER)
                    AuthService.logout();
                })
            var dt = new Date();
            dt.setSeconds(dt.getSeconds() + res.data.expires_in);
            res.data.expires_in = dt;
            if (!stayLoggedIn) {
                delete res.data.refresh_token
            }
            localStorage.setItem("user-token", JSON.stringify(res.data));
            getTokenPromise = null;
            return res.data;
        }).catch(error => {
            getTokenPromise = null;
            return Promise.reject(error);
        });
    }
    return getTokenPromise;
};

const refreshToken = () => {
    if (!getTokenPromise) {
        var data = new URLSearchParams();
        data.append("refresh_token", getToken().refresh_token);
        data.append("grant_type", "refresh_token");
        data.append("scope", "API offline_access openid");
        data.append("client_id", Config.CLIENT_ID);
        data.append("Client_secret", Config.CLIENT_SECRET);
        var conf = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        getTokenPromise = axios.post(`${Config.AUTH_URL}/connect/token`, data, conf).then(res => {
            var dt = new Date();
            dt.setSeconds(dt.getSeconds() + res.data.expires_in);
            res.data.expires_in = dt;
            localStorage.setItem("user-token", JSON.stringify(res.data));
            getTokenPromise = null;
            return res.data;
        }).catch(err => {
            return Promise.reject(err);
        });
    }
    return getTokenPromise;
};

const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    let Socket = store.getState().FalkonWebSocket;
    if (Socket) {
        Socket.close(4001, "Manual disconnect by user");
    }
    window.location.replace(AppRoutes.SIGN_IN);
};

const getToken = () => {
    var token = localStorage.getItem("user-token");
    return !!token ? JSON.parse(localStorage.getItem("user-token").toString()!) : {};
};

const loggedIn = () => {
    var token = getToken();
    return !!token && !!token.access_token && (!!token.sso || ((!!token.expires_in && new Date(token.expires_in) > new Date()) || !!token.refresh_token));
};


const isTokenExpired = () => {
    var token = getToken();
    return !!token && !!token.access_token && !!token.refresh_token && !!token.expires_in && new Date(token.expires_in) <= new Date();
};

const is2FACompleted = () => {
    return localStorage.getItem("2FAVerified")?.toString() !== null && localStorage.getItem("2FAVerified")?.toString() === "true"
}

const isNewUser = () => {
    var user = JSON.parse(localStorage.getItem("current-user")!);
    return !!user && user.lastLoggedIn == null
}

const isUserLoggedIn = () => {
    return !!getToken() && is2FACompleted() && !!JSON.parse(localStorage.getItem("current-user")) && !isNewUser()
}

const microSoftLogin = (token, navigate) => {

    var conf = { headers: { 'Content-Type': 'application/json' } };
    let userEmail = localStorage.getItem("teamsUserEmail")
    let data = {
        "Token": token,
        "EmailId": userEmail
    }
    axios.post(`${Config.AUTH_URL}/api/SSOAuth/teams`, data, conf)
        .then(res => {
            if (!!res.data) {
                localStorage.setItem("2FAVerified", "true");
                var dt = new Date();
                dt.setSeconds(dt.getSeconds() + res.data.expires_in);
                res.data.expires_in = dt;
                localStorage.setItem("user-token", JSON.stringify(res.data));
                UserService.getCurrentUser().then((res: any) => {
                    sessionStorage.setItem('isSSOLoginLoading', 'false')
                    navigate(UserService.hasSysAdminRole() ? AppRoutes.PHONE_NUMBERS : AppRoutes.DASHBOARD, { replace: true });
                    window.location.pathname.includes("teams") && navigate(0);
                });
            }
        }).catch(err => {
            if (err?.response?.data?.error_message === 'INACTIVE_USER' || err.response?.data?.error_message === 'INACTIVE_ORGANIZATION' || err.response?.data?.error_message === 'INACTIVE_PHONE_NUMBER' || err.response?.data?.error_message === 'INVALID_EMAIL' || err.response?.data?.error_message === "PASSWORD_NOT_SET") {
                sessionStorage.setItem('isSSOLoginLoading', 'false')
                navigate(AppRoutes.SSO_ERROR_PAGE.replace(':state', err?.response?.data?.error_message));
                // navigate(0);
            }
            else if (err.code === "ERR_BAD_REQUEST") {
                sessionStorage.setItem('isSSOLoginLoading', 'false')
                setToast(ToasterTexts.SOMETHING_WENT_WRONG_MESSAGE, ToasterType.DANGER)
            }
        })
}

const ssoLoginConfig = (search, navigate) => {
    if (!!search) {
        const params = new URLSearchParams(search);
        const access_token = params.get('access_token')
        const refresh_token = params.get('refresh_token')
        const token_type = params.get('token_type')
        const scope = params.get('scope')
        let expires_in: any = params.get('expires_in')
        var dt = new Date();
        dt.setSeconds(dt.getSeconds() + Number(expires_in));
        expires_in = dt;
        const result = params.get('Result');
        if (result.toLowerCase() === 'true' && access_token) {
            localStorage.clear();
            localStorage.setItem('user-token', JSON.stringify({ access_token, sso: true, refresh_token, token_type, scope, expires_in }));
            localStorage.setItem('2FAVerified', 'true');
            UserService.getCurrentUser().then((res: any) => {
                if (!!res.data && !res.data.lastLoggedIn) {
                    sessionStorage.setItem('isSSOLoginLoading', 'false')
                    navigate(AppRoutes.RESET_PASSWORD_NEW);
                    // navigate(0);
                }
                else {
                    sessionStorage.setItem('isSSOLoginLoading', 'false')
                    navigate(res.data.roleId === 1 ? AppRoutes.PHONE_NUMBERS : AppRoutes.DASHBOARD);
                    // navigate(0);
                }
            });
        }
        else if (result?.toLowerCase() === 'false') {
            sessionStorage.setItem('isSSOLoginLoading', 'false')
            setToast(ToasterTexts.SOMETHING_WENT_WRONG_MESSAGE, ToasterType.DANGER)
        }
        else if (result === 'INACTIVE_USER' || result === 'INACTIVE_ORGANIZATION' || result === 'INACTIVE_PHONE_NUMBER' || result === 'INVALID_EMAIL' || result === "PASSWORD_NOT_SET") {
            navigate(AppRoutes.SSO_ERROR_PAGE.replace(':state', result));
            // navigate(0);
            sessionStorage.setItem('isSSOLoginLoading', 'false')
        }
        else {
            sessionStorage.setItem('isSSOLoginLoading', 'false')
            setToast(ToasterTexts.SOMETHING_WENT_WRONG_MESSAGE, ToasterType.DANGER)
        }
    }
    else {
        sessionStorage.setItem('isSSOLoginLoading', 'false')
    }
}

const isSSOLoginLoading = () => {
    return sessionStorage.getItem('isSSOLoginLoading') === 'true'
}
interface ActivityFeedLoginData {
    teamsAppId: string;
    userId: string;
    phoneNumber: string;
    tenantID: string;
    notification_status: boolean;
    orgID: string;
}



interface ActivityFeedLoginData {
    teamsAppId: string;
    userId: string;
    phoneNumber: string;
    tenantID: string;
    notification_status: boolean;
    orgID: string;
}

const activityFeedLogin = (data: ActivityFeedLoginData) => {
    axios.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/activityFeed/login/user`, data).then(res => {
        if (res.status === 200) {
            // sendNotification()
        }
    }).catch(err => {
        console.log("err", err);
        setToast(ToasterTexts.SOMETHING_WENT_WRONG_MESSAGE, ToasterType.DANGER)
    })
}

const activityFeedLogout = (userId: string) => {
    console.log("userId:", userId)
    axios.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/activityFeed/logout/${userId}`).then(res => {
        console.log("res:", res)
        logout();
    }).catch(err => {
        console.log("err:", err)
    })
}

const sendNotification = () => {
    const activityInfo = localStorage.getItem("activityInfo") && JSON.parse(localStorage.getItem("activityInfo")!)
    const phoneNumber = UserService.getUserPhoneNumber()

    const notificationData = {
        "userIds": [
            activityInfo.userId,
        ],
        "phoneNumber": phoneNumber,
        "senderId": "sender-id-123",
        "senderName": "raghav",
        "contactName": "Jane Smith",
        "isOutbound": "true",
        "content": {
            "title": "New Message",
            "message": "Hello, this is a sample message.",
            "mmsFileName": "image.png",
            "fileContent": "SGVsbG8gd29ybGQ="
        }
    }
    axios.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/send/activity/feed/notification`, notificationData).then(res => {
        console.log("res", res);

    }).catch(err => {
        console.log("err", err);
        setToast(ToasterTexts.SOMETHING_WENT_WRONG_MESSAGE, ToasterType.DANGER)
    })
}

export const AuthService = {
    login,
    refreshToken,
    logout,
    getToken,
    loggedIn,
    isUserLoggedIn,
    isTokenExpired,
    is2FACompleted,
    microSoftLogin,
    ssoLoginConfig,
    isSSOLoginLoading,
    isNewUser,
    activityFeedLogin,
    activityFeedLogout
};